<template>
  <div class="container">
    <v-widget  :title="$t('twofactor_stepper_title')">
      <template #content>
        <Index :reset="twofactor" :redirect="true" />
      </template>
    </v-widget>
  </div>
</template>

<script>
import VWidget from '@/components/commons/VWidget'
import Index from '@/views/2fa/Index'
import { mapState } from 'vuex'

export default {
  components: { VWidget, Index },
  computed: {
    ...mapState('auth', ['user'])
  },
  created() {
    this.twofactor = this.user?.twofactor_habilitated
  },
  data(){
    return {
      twofactor: null
    }
  }
}
</script>
<style scoped>
.container {
  max-width: 1264px;
}
</style>
